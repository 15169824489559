import './js/notifixInit.js';
import './js/btnLoader.js';
import './js/heroVh.js';
import './js/swiper.js';
import './js/swiperDetailedPlanner.js';
import './js/order-module.js'; // розкоментувати для завантаження модуля редіректу на сторінку замовлення
import './js/animation.js';
import './js/button-up.js';
import './js/onPreOrderFormSubmit.js';
import './js/preLoader.js';
import './js/footer.js';
import './js/socialButton.js';
import './js/successPayment.js';
import './js/services/crm-lead-post.js';
import './js/description.js';
import { toggleList } from './js/toggleList.js';
import './js/notifixInit.js';
import 'intl-tel-input/build/css/intlTelInput.css';
import './js/succesPreOrderModal';
import './js/video';
